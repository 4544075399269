import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnowFlakeContext } from "./SnowFlakeContext";
import { GlobalNotificationHandle } from "../Logic/NotificationHandler";
import { useAuth } from './AuthContext';
import { useReactFlowContext } from "./reactFlowContext";
import { useSocketioContext } from './SocketioContext';
import { AUTOSAVING, LOADING, LOADED, SYNCED } from '../Types/DesignStatusTypes'  

export const DesignsContext = createContext(null)

export const DesignsProvider = ({ children }) => {
  const navigate = useNavigate()

  // Accessing states & functions from AuthContext - (Logged user)
  const { user } = useAuth()

  // Accessing function to get the JSON object of the design from reactFlowContext
  const { getDesignObject } = useReactFlowContext()

  // Accessing function to initialize the design page's dropdown sidebar
  const { initializeDatabaseState, retainDatabaseValue } = useSnowFlakeContext()

  // To access socket for creating a room and joining
  const { socket } = useSocketioContext()

  const [ currentDesign, setCurrentDesign ] = useState({
    designName: 'Untitled', 
    lastSaveTimeStamp: 0,
    savingStatus: LOADED,
    data: null,
    designId: null,
    updatedByDesign: false,
    authorized: false,
    businessName: 'Untitled',
  })
  const [ sharedDesignObject, setSharedDesignObject ]= useState({})

  const [ currentDesignActiveUsers, setCurrentDesignActiveUsers ] = useState([])

  useEffect(() => {
    if(socket !== null && socket !== undefined) {
      socket.on('receiveDesign', (design) => {
        if(design?.data?.elements?.length > 0 ) {
          const node = design.data.elements.find(element => element.data !== undefined)
          retainDatabaseValue(node.data.database, node.data.schema)
        } else {
          initializeDatabaseState()
        }
        setCurrentDesign(prevState => {
          return {
            ...prevState, 
            designName: design?.tableName || 'Untitled',
            lastSaveTimeStamp: design?.lastUpdated,
            savingStatus: LOADED,
            data: design.data,
            designId: design?.tableId,
            updatedByDesign: true,
            authorized: design?.authorized || false,
            businessName: design?.businessName,
            databaseName: design?.databaseName
          }
        })
        navigate('/design')
      })

      socket.on('syncDesign', (design) => {
        if(Object.keys(design).length === 0) {
          autoSaveLoadingAnimation(SYNCED)
          GlobalNotificationHandle({ key: 'NO_MORE_CHANGES' })
        } else {
          setCurrentDesign(prevState => {
            return { 
              ...prevState, 
              lastSaveTimeStamp: design?.lastUpdated,
              savingStatus: SYNCED,
              data: design?.data,
              updatedByDesign: false,
              designId: design?.chart,
            }
          })
        }
      })
    }
  }, [socket])

  const openDesign = async ( designId, sharedDesignData ) => {
    // Hit '/get' endpoint to get the design data for the respective design ID
    setSharedDesignObject(sharedDesignData)
    socket.emit('getDesign', {
      userId: user?.attributes?.name,
      tableId: designId
    })
  }

  const updateDesign = async () => {
    if(currentDesign.authorized === true) {
      const designData = await getDesignObject()
      // Hit '/update' endpoint to store the updated design data in the server
      if(designData !== null ) {
        autoSaveLoadingAnimation(AUTOSAVING)
        socket.emit('updateDesign', {
          userId: user?.attributes?.name,
          tableId: currentDesign?.designId,
          data: designData,
          lastUpdated: currentDesign?.lastSaveTimeStamp,
          businessName: currentDesign?.businessName,
        }, (newTimeStamp) => {
          if(newTimeStamp !== undefined && newTimeStamp !== null) {
            setCurrentDesign(prevState => {
              return { 
                ...prevState, 
                lastSaveTimeStamp: newTimeStamp,
                savingStatus: SYNCED,
                data: designData,
                updatedByDesign: true,
                designId: currentDesign?.designId,
                businessName: currentDesign?.businessName,
              }
            })
          }
        })
      }
    }
  }

  const designRename = async (newBusinessName) => {
    setCurrentDesign(prevState => {
      return {
        ...prevState,
        businessName: newBusinessName,
        savingStatus: AUTOSAVING,
      }
    })
    const designData = await getDesignObject()
    socket.emit('updateDesign', {
      userId: user?.attributes?.name,
      tableId: currentDesign?.designId,
      data: designData,
      lastUpdated: currentDesign?.lastSaveTimeStamp,
      businessName: newBusinessName,
    }, (newTimeStamp) => {
      if(newTimeStamp !== undefined && newTimeStamp !== null) {
        setCurrentDesign(prevState => {
          return { 
            ...prevState, 
            lastSaveTimeStamp: newTimeStamp,
            savingStatus: SYNCED,
            data: designData,
            updatedByDesign: true,
            designId: currentDesign?.designId,
            businessName: newBusinessName,
          }
        })
      }
    })
  }

  const undoDesign = async () => {
    autoSaveLoadingAnimation(LOADING)
    socket.emit('undoDesign', {
      userId: user?.attributes?.name,
      tableId: currentDesign.designId,
      lastUpdated: currentDesign.lastSaveTimeStamp
    })
  }

  const redoDesign = async () => {
    autoSaveLoadingAnimation(LOADING)
    socket.emit('redoDesign', {
      userId: user?.attributes?.name,
      tableId: currentDesign.designId,
      lastUpdated: currentDesign.lastSaveTimeStamp
    })
  }

  const autoSaveLoadingAnimation = (status) => {
    setCurrentDesign(prevState => {
      return { ...prevState,
                  savingStatus: status
      }
    })
  }


  return (
    <DesignsContext.Provider 
      value={{currentDesign, setCurrentDesign, openDesign, autoSaveLoadingAnimation, updateDesign, undoDesign, redoDesign, currentDesignActiveUsers, setCurrentDesignActiveUsers, designRename, sharedDesignObject }}
    >
      {children}
    </DesignsContext.Provider>
  )
}

export const useDesignsContext = () => {
  return useContext(DesignsContext)
}