import React, { useMemo } from "react";
import classNames from "classnames";
import {ReactComponent as SearchIcon} from '../Assets/svg/searchIcon.svg';
import CancelIcon from '@mui/icons-material/Cancel';

const MainContentTopSearch = ({searchResult, setSearchResult,account, getData, currentPage, setCurrentPage, limit, currentDB, selectedValue}) => {
  const resetSearch = () => {
    setSearchResult("");
    setCurrentPage(1);
    getData(account, 1, limit, selectedValue, currentDB, "");
  }
  function handleSubmit(e) {
    e.preventDefault();
  }

  const handleSearchChange = () => {
    let timeOutId;
    return (e) => {
      const searchVal = (e.target.value);      
      setSearchResult(searchVal);
      setCurrentPage(1);
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        getData(account, currentPage, limit, selectedValue, currentDB, searchVal);
      },500);
    };
    
  };
  const optimizedFn = useMemo(() => handleSearchChange(), [account, currentPage, limit, selectedValue, currentDB]);
  const hideCancelButton = searchResult !== "" ? false : true
return (
    <form  className="main-content-top-search-bar" onSubmit={handleSubmit}>
      <div className="main-content-top-search-icon" >
        <SearchIcon />
      </div>
      <input
        type="text"
        placeholder="Search..."
        value={searchResult}
        onChange={optimizedFn}
        className="main-content-top-search-input"
      />
      <div className={classNames('main-content-top-cancel-icon', {'cancel-icon-hidden': hideCancelButton})} onClick={resetSearch} >
        <CancelIcon fontSize="small" />
      </div>
    </form>
  );
};


export default MainContentTopSearch;
