
import React, { useState, useRef } from "react";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import CancelIcon from '@mui/icons-material/Cancel';
import {ReactComponent as SearchIcon} from '../Assets/svg/searchIcon.svg';

const SearchTable = ({ searchWord, setSearchWord  }) => {
  const containerRef = useRef();

  const handleSearch = (event) => {
    const newSearch = event.target.value.toUpperCase()
    setSearchWord(newSearch)
  }

  const resetSearch = () => {
    setSearchWord("");
  }
  
  const hideCancelButton = searchWord !== "" ? false : true

  return (
    <div ref={containerRef} style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    {/* Search Icon Inside the Input Field */}
    <div className="table-search-icon">
      <SearchIcon width="16" height="24" />
    </div>

    {/* Search Input */}
    <input
      type="text"
      placeholder="Search Table Name and Created Objects"
      value={searchWord}
      onChange={handleSearch}
      onFocus={(e) => e.target.style.borderColor = '#ccc'} 
      className="table-search-input"
    />
      <div className={classNames('main-content-top-cancel-icon', {'cancel-icon-hidden': hideCancelButton})} onClick={resetSearch} style={{position:'relative', marginBottom:'2px', right:'28px'}}>
        <CancelIcon fontSize="small" />
      </div>
    </div>
  );
};

export default SearchTable;