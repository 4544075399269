export const CLIENT_ERROR="error"
export const CLIENT_SUCCESS="success"
export const CLIENT_WARNING="warning"
export const CLIENT_INFO="info"
export const ALERT_DETAILS={
  "GENERAL_ERROR_MESSAGE": {
    message: "{{error}}, Please reload application...",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CANNOT_DELETE": {
    message: "Cannot delete {{nodeName}} now, since {{nodeSettingsOpenedBy}} is working on node settings",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CANNOT_DELETE_EDGE": {
    message: "Cannot delete an edge of a \"HUB||SAT||LINK||LSAT\" dragged connection",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CANNOT_DELETE_EDGE_OF_NODE": {
    message: "Cannot delete an edge of node's dragged connection",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "ALREADY_IN_USE": {
    message: "Node settings for respective node is being worked on by user {{user}}",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "NO_MORE_CHANGES": {
    message: "No more design changes to load",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "NODE_ALREADY_EXIST": {
    message: "Node ({{nodeName}}) already exists",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "TARGET_LINK_NOT_CONNECTED": {
    message: "Target LINK ({{linkName}}) not connected. Can not overload LSATs of not connected links",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "OVERLOAD_LSAT": {
    message: "Cannot overload an LSAT of a \"HUB||SAT||LINK||LSAT\" dragged connection",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SAME_LSAT": {
    message: "If you want to overload an existing LSAT, please drag and drop a different source table (Current source table: {{sourceTableName}})",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "LINK_NODE_PENDING": {
    message: "Link Node ({{element}}) connections are still pending",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "XLINK_NODE_PENDING": {
    message: "Xlink Node ({{element}}) connections are still pending",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "MULTIPLE_TABLE_NAME_EXISTS": {
    message: "Multiple tables with name {{element}} found",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SETTINGS_PEDDING": {
    message: "{{element}} table settings are still pending",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "RENAME_PENDING": {
    message: "{{element}} rename is still pending",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "OVERLOAD_PENDING": {
    message: "Overloaded {{element}} source tables object selection is still pending",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CANT_OVERLOAD": {
    message: "Node {{element}} can not be overloaded with the same object twice.",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "ABBREVATION_NOT_SET": {
    message: "Table abbreviation in {{element}} is not set",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "NOT_SET": {
    message: "{{type}} in {{element}} are not set`",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "UNEXPECTED_ERROR": {
    message: "Unexpected error occured: {{e}}.",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SOURCE_SYSTEM_CANNOT_BE_FALSE": {
    message: "Both Source system deleted field indicator & talend job deleted field indictor in {{element}} cannot be false",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CREATE_NODE_AND_CONNECTIONS": {
    message: "Please create Nodes & Connections",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SELECT_CHECKBOX": {
    message: "Please select at least one checkbox",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SELECT_DESTINATION_SCHEMA": {
    message: "Please select destination schema",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SELECT_DESTINATION_DATABASE": {
    message: "Please select destination database",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "CONNECTION_NOT_ALLOWED": {
    message: "Connection not allowed between {{sourceNodeType}} node and {{targetNodeType}} node",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "SELECT_TO_OVERLOAD": {
    message: "Please select the same schema {{schema}} to overload {{label}}",
    variant: CLIENT_ERROR,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "PROCEDURES_GENERATED": {
    message: "Files generated successfully",
    variant: CLIENT_SUCCESS,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "MULTIPLE_DATABASES": {
    message: "Caution: Multiple Databases have been selected for the PSA tables. The name of the database will not be included in the generated procedures.",
    variant: CLIENT_WARNING,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "DBT_UNAVAILABLE": {
    message: "Your currently selected version is DVA 1.2.0. DBT generation is not supported in this version. If you want to choose DBT as your file type, please select version 1.3.0 or higher from the home page.",
    variant: CLIENT_INFO,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "DBT_UNSUPPORTED": {
    message: "DBT file generation is not supported for HUB, LINK & REF Nodes.",
    variant: CLIENT_INFO,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
  "DBT_UNSUPPORTED_OLD_VERSION": {
    message: "DBT file generation is not supported for HUB, LINK, REF & XREF Nodes. DBT file generation for XREF node is supported from version v1.5.0 and upwards.",
    variant: CLIENT_INFO,
    isIncident: false,
    isTicketIssue: false,
    isDismissable: false
  },
}