export const introduction = {
    "firstLine": "Data Vault Automation (DVA) app is a Roche built application that is supported on 3 pillars:",
    "bulletList": [
        "Collaboration & Self Service",
        "Component Design",
        "Automation"
    ],
    "lastLine": "In a nutshell, it allows you to build your Data Vault 2.0 model without having to write a single line of code yourself."
}
export const faqs = [
    {
        "question": "High level view on architecture",
        "content1": "The data in our platform flows through 3 distinct layers:",
        "list": [
            {
                "text": "Persistent Staging Layer - this is where all the data land from your source system. It lands in the same format, independent of the source system. PSA schemas belong to this layer.",
            }, {
                "text": "Data Modeling Layer - in this layer, relationships between data are introduced. RAW_DV schemas and BUSINESS_DV schemas belong to this layer.",
            },
            {
                "text": "Performance Layer - this is where Data Product specific combinations and special cases of data are stored. DP schemas belong to this layer."
            }
        ],
        "content2": "DVA helps to model the data that ends up in the Data Modeling Layer."
    },
    {
        "question": "Why not just skip all the layers and dump everything from the source system to the Performance Layer?",
        "para": [
            "The short answer is due to reusability, long term maintenance and speed to market.",
            "All the PSAs and RAW_DVs on the platform follow the same structure. In the case where cross team cooperation is needed, new data can be built easily on existing architecture. If you skip these layers, you lose this option. Even within the team, the final consumable data product may change with time. If you have the structure in place, the same underlying data can be reused for new or changing data products.",
            "As a concrete example, imagine your data product involves data about users. You create your Data Modeling layer with a “Users” hub and eventually publish your data product. Suddenly you have a new source coming in that has information about users that you did not have in your model before. To add this new info is easy on our platform - dump your data into PSA, add a new satellite to an existing hub in RAW_DV and add in extra columns in your existing data product as needed. You do not have to rethink how this new data fits in with the existing model and you do not have to completely rework your whole model to use this data.",
            "The separation of layers also helps with speed to market. First, you can just focus on getting data into the system (Persistent Staging Layer), then on understanding the relationships (Data Modeling Layer) then on building the final product (Performance Layer).",
            "Without the structure in place, you live in the world of a data lake that can easily become a data swamp, where all the data is available, but it is extremely difficult to find and reuse."
        ]

    },
    {
        "question": "How do you use DVA?",
        "isDVAUse": true,
        "list":
            [
                {
                "text": "Access",
                "access": [{
                        "heading": "EDIT access",
                        "para": [{
                            "text":"DVA can be tried out in a Sandbox environment, which is automatically uniquely provisioned for each user at first login. Users can also take part in editing the shared designs on the main page if they have access to do so. Shared designs are separated per team (or per domain for early RDDM adapters). In order to edit these shared designs, users need access to the underlying RAW_DV. Edit rights are granted to users who have a developer or admin role on this raw_dv schema in Snowflake. Roles can be requested ",
                            "link": {
                                "text": "here.",
                                "url": "https://cidm.roche.com/identityiq/accessRequest/accessRequest.jsf#/accessRequestSelf/add"
                            }
                        }]  
                }, {
                        "heading": "READ access",
                        "para": [{
                            "text": "All metadata of any domain can still be read without any access rights on the underlying data within DVA. All authenticated users have read access rights on all domains.",
                        },
                        {
                            "text": "NEW domain or team. In case of a new team or domain, please raise a request for a new database and new architecture to be created for you via a Support Ticket ",
                            "link": {
                                "text": "here.",
                                "url": "https://roche.service-now.com/icare?id=sc_cat_item&sys_id=a5c462af875952149508c8480cbb3524"
                            }
                        }]
                }],
            },
            {
                "text": "Required Architecture",
                "downloadButtonTxt": "Download required architecture",
                "toolTip": "The Download contains all the required SQL DDL statements needed for DVA together with example structures.",
                "para": [
                    {
                        "text": "CONFIG_{your_abbreviation}_OBJECT_SCHEMA - this table will contain all the schemas for your objects in the source system. The metadata of your data, or data schemas, are inserted into this table via your ingestion job at the same time as data is inserted into your PSA schemas. Types are as they are in your source system."
                    },
                    {
                        "text": "CONFIG_DATA_TYPES - this table contains the data type mappings from the source system data types (from CONFIG_{your_abbreviation}_OBJECT_SCHEMA) to datatypes in Snowflake. More information on how to fill in the CONFIG_DATA_TYPES table can be found ",
                        "link":{
                            "text":"here",
                            "url":"https://rochewiki.roche.com/confluence/display/diasnflkedw/CONFIG_DATA_TYPES+update"
                        }
                    },
                    {
                        "text": "CONFIG_PROCEDURE_STATUS - this table is automatically filled in with procedure logs after they run. You can query this table to debug the possible problems with procedure runs."
                    }
                ]
            },
            {
                "text": "Step by Step process",
                "numberList": [
                    "Make sure you have the required tables.",
                    "Make sure the data is in the necessary format.",
                    "Make sure the data types are mapped properly and none of the mappings are missing.",
                    "Watch the DVA tutorial videos to know how to use the app."
                ]
            }
            ]


    },
    {
        "question": "What is Data Vault 2.0?",
        "content1": "Data vault modeling is a database modeling method that is designed to provide long-term historical storage of data coming in from multiple operational systems. A comprehensive guide can be found ",
        "link1": {
            "text": "in our confluence page.",
            "url": "https://rochewiki.roche.com/confluence/display/diasnflkedw/Data+Vault+Methodology"
        },
        "content2": " For video guides and FAQs about Data Vault specifically, make sure to check out our Data Vault 2.0. Centre of Excellence Sessions, which can be found ",
        "link2": {
            "text": "here.",
            "url": "https://rochewiki.roche.com/confluence/pages/viewpage.action?spaceKey=diasnflkedw&title=Data+Vault+2.0+Centre+of+Excellence+Sessions"
        }
    },
    {
        "question": "How to backup nodes from a shared design?",
        "content1": "All data within DVA is fully historised and thus all of the user actions are automatically backed up and can be accessed via “undo” and “redo” buttons on the design."

    },
    {
        "question": "How to version the designs?",
        "list": [
            {
                "text": "Currently, versioning is not supported by DVA, but it is one of the planned features on the backlog. The best way to achieve versioning in the meantime is by leveraging the Copy & Paste feature. Users can copy the shared design (or a subset of it) into their sandbox and do the additional changes there. Please keep in mind that the sandbox space for each user is unique and can not be worked on by other users.",
            },
            {
                "text": "To use this feature, press and hold SHIFT key along with the left mouse button, then drag the mouse and select the required nodes to be backed up. Once nodes are selected release both the SHIFT key and left mouse button. To copy the selected nodes, press CTRL key along with “C”. All selected nodes will be copied. Go to your sandbox, then press CTRL key along with “V” key to paste the selected nodes."
            }
        ]
    },
    {
        "question": "How to clone nodes between shared designs?",
        "list": [
            {
                "text": "One option is using the Copy & Paste feature. To use this feature, press and hold SHIFT key along with the left mouse button, then drag the mouse and select the required nodes to be backed up. Once nodes are selected release both the SHIFT key and left mouse button. To copy the selected nodes, press CTRL key along with “C”. All selected nodes will be copied. Go to your target shared design, then press CTRL key along with “V” key to paste the selected nodes.",
            }, {
                "text": "If the needed move is too big or complex to be achieved by this, please raise a ",
                "link": {
                    "text": "platform support ticket.",
                    "url": "https://roche.service-now.com/icare?id=sc_cat_item&sys_id=a5c462af875952149508c8480cbb3524"
                }
            }
        ],

    },
    {
        "question": "How to rename a shared design?",
        "content1": "In the Action Bar on top of the screen within the shared design, click on the current design name in order to edit it. Enter the design name and press enter or click outside the input field to save your changes. The rename can be undone by the undo button on the design."
    },
    {
        "question": "How to create an Overloaded satellite?",
        "content1": "Create one HUB and SAT pair that you want to overload. Then select the second table, the one that you want to overload with, as if you want to create a different satellite to the same HUB. Drop this new SAT on the original SAT instead of dropping it anywhere else on the design as you would do usually. You will know you are successful, if the Overloaded satellite icon ",
        "isIcon":true,
        "content2":" appears on the original satellite.",
    },
    {
        "question": "How to use Undo & Redo?",
        "content1": "Undo & Redo can be triggered from the top Action Bar in the design. Just clicking the buttons does not update the design to that state immediately. Click the undo & redo buttons until the desired state in the design is reached, then interact with one of the nodes, with a drag action for example, to save the desired state for the design as the latest one."
    },
    {
        "question": "Is the Sandbox also shared with others? Will other users get impacted if I do some changes in Sandbox?",
        "content1": "Sandbox designs are unique for every user and they cannot be accessed by other users. So any changes done by a user in their Sandbox design will not impact any other user."
    },
    {
        "question": "What does the error “Configure DATA_TYPE and DATA_TYPE_MAPPING in destination DB” mean?",
        "content1": "This is a specific error message to inform the user that the pre-configuration steps are not completed yet. Please follow the steps in the ",
        "jumpLink":{
            "text":'“How do you use DVA?” -> “Required Architecture” section of this tutorial.',
            "jumpQuestion":"How do you use DVA?"
        }
    }
]
