import { useState, createContext, useContext } from "react";
import {
  HUB_SATELLITE,
  LINK_SATELLITE,
  REF_SATELLITE,
  XREF,
  XLINK_DELTA,
  XLINK_FULL,
  HUB_SAT_LINK_LSAT,
  DATA,
  SCHEMA,
  LABEL,
  HUB_CONNECTIONS,
  LINK_STATUS,
  ENABLE_NODE_SETTINGS,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  DRAGGED_CONNECTION,
  CONNECTION_TYPE,
  USER_SETTINGS,
  BUSINESS_KEY,
  CONNECTED_ENTITIES,
  SOURCE_TABLE,
} from '../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes';
import { useSocketioContext } from './SocketioContext';
import { useReactFlowContext } from './reactFlowContext';
import { useDesignsContext } from "./DesignsContext";
import { GlobalNotificationHandle } from "../Logic/NotificationHandler";
import { removeElements, isNode } from 'react-flow-renderer';

export const PlaygroundContext = createContext(null)

export const PlaygroundContextProvider = ({ children }) => {
  const { getLsatConnections, getNodeById, getLinkXlinkConnections, getNodesByNodeIdList, getSatConnections } = useReactFlowContext()
  const [elements, setElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState({})
  const { socket } = useSocketioContext()
  const { updateDesign, currentDesign } = useDesignsContext()
  const updateDelay= 250



  const onElementsRemove = async (elementsToRemove) => {
    const selectedNode = elementsToRemove[0]
    let updatedElementsListToRemove = [], nodeSettingsOpenedBy = '', sourceNode, targetNode, nodesListToBeChecked = [], linkXlinkConnections = [], nodesIdListToRemove = [], satConnections = [], lsatConnections = [], getConnectedHubNode, getConnectedLinkNode
    if(isNode(selectedNode)) {
      if(window.confirm(`Node ${selectedNode[DATA][LABEL]} will be deleted`)) {
        switch(selectedNode.type) {
          case 'hubnode':
            satConnections = getSatConnections(selectedNode)
            if(satConnections.length > 1) {
              nodesListToBeChecked.push(selectedNode.id)
              satConnections.forEach(connectedSatNode => nodesListToBeChecked.push(connectedSatNode.id))
              // nodesIdListToRemove = [ ...nodesListToBeChecked ]
              nodesIdListToRemove = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES], ...nodesListToBeChecked ]
            } else {
              nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              nodesIdListToRemove = [ ...nodesListToBeChecked ]
            }
            linkXlinkConnections = getLinkXlinkConnections(selectedNode)
            linkXlinkConnections.forEach(linkXlinkNode => {
              if(linkXlinkNode.type === 'linknode') {
                if(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2)  nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][3])
                else nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
              }
              else nodesListToBeChecked.push(linkXlinkNode.id)
            })
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy === '') {
              linkXlinkConnections.forEach(linkXlinkNode => {
                handleLinkStatus(linkXlinkNode.id, selectedNode.id, 'remove')
              })
            } else {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = getNodesByNodeIdList(nodesIdListToRemove)
            break
          case 'refnode':
            satConnections = getSatConnections(selectedNode)
            if(satConnections.length > 1) {
              nodesListToBeChecked.push(selectedNode.id)
              satConnections.forEach(connectedSatNode => nodesListToBeChecked.push(connectedSatNode.id))
              nodesIdListToRemove = [ ...nodesListToBeChecked ]
            } else {
              nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              nodesIdListToRemove = [ ...nodesListToBeChecked ]
            }
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy !== '') {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = getNodesByNodeIdList(nodesIdListToRemove)
            break
          case 'satnode':
            getConnectedHubNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0])
            satConnections = getSatConnections(getConnectedHubNode)
            if(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
              nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              satConnections.forEach(satConnection => nodesListToBeChecked.push(satConnection.id))
              getConnectedLinkNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2])
              lsatConnections = getLsatConnections(getConnectedLinkNode)
              lsatConnections.forEach(lsatConnection => nodesListToBeChecked.push(lsatConnection.id))
              nodesIdListToRemove = [ ...nodesListToBeChecked ]
              linkXlinkConnections = getLinkXlinkConnections(getConnectedHubNode)
              linkXlinkConnections.forEach(linkXlinkNode => {
                if(linkXlinkNode.type === 'linknode') {
                  if(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2)  nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][3])
                  else nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
                }
                else nodesListToBeChecked.push(linkXlinkNode.id)
              })
            } else {
              if(satConnections.length > 1) {
                nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
                if(getConnectedHubNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] === selectedNode.id) {
                  for(let satConnection of satConnections) {
                    if(getConnectedHubNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] !== satConnection.id) {
                      updateHubLinkConnectedEntities(getConnectedHubNode.id, satConnection)
                      break
                    }
                  }
                }
                nodesIdListToRemove.push(selectedNode.id)
              } else {
                nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
                nodesIdListToRemove = [ ...nodesListToBeChecked ]
                linkXlinkConnections = getLinkXlinkConnections(getConnectedHubNode)
                linkXlinkConnections.forEach(linkXlinkNode => {
                  if(linkXlinkNode.type === 'linknode') {
                    if(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2)  nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][3])
                    else nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
                  }
                  else nodesListToBeChecked.push(linkXlinkNode.id)
                })
              }
            }
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy === '') {
              linkXlinkConnections.forEach(linkXlinkNode => {
                handleLinkStatus(linkXlinkNode.id, selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0], 'remove')
              })
            } else {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = getNodesByNodeIdList(nodesIdListToRemove)
            break
          case 'lsatnode':
            if(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
              nodesListToBeChecked = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              getConnectedLinkNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][2])
              lsatConnections = getLsatConnections(getConnectedLinkNode)
              lsatConnections.forEach(lsatConnection => nodesListToBeChecked.push(lsatConnection.id))
              getConnectedHubNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0])
              satConnections = getSatConnections(getConnectedHubNode) 
              satConnections.forEach(satConnection => nodesListToBeChecked.push(satConnection.id))
              nodesIdListToRemove = [ ...nodesListToBeChecked ]
              linkXlinkConnections = getLinkXlinkConnections(getConnectedHubNode)
              linkXlinkConnections.forEach(linkXlinkNode => {
                if(linkXlinkNode.type === 'linknode') {
                  if(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2)  nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][3])
                  else nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
                }
                else nodesListToBeChecked.push(linkXlinkNode.id)
              })
            } else {
              getConnectedLinkNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0])
              lsatConnections = getLsatConnections(getConnectedLinkNode)
              if(lsatConnections.length > 1) {
                nodesListToBeChecked.push(selectedNode.id)
                if(getConnectedLinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] === selectedNode.id) {
                  for(let lsatConnection of lsatConnections) {
                    if(getConnectedLinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] !== lsatConnection.id) {
                      updateHubLinkConnectedEntities(getConnectedLinkNode.id, lsatConnection)
                      break
                    }
                  }
                }
                nodesIdListToRemove.push(selectedNode.id)
              } else {
                nodesListToBeChecked.push(selectedNode.id)
                nodesIdListToRemove = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              }
            }
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy === '') {
              linkXlinkConnections.forEach(linkXlinkNode => {
                handleLinkStatus(linkXlinkNode.id, getConnectedHubNode.id, 'remove')
              })
            } else {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = getNodesByNodeIdList(nodesIdListToRemove)
            break
          case 'xlinknodedelta':
          case 'xlinknodefull':
          case 'xrefnode':
            nodesListToBeChecked.push(selectedNode.id)
            nodesIdListToRemove = [ ...nodesListToBeChecked ]
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy !== '') {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = (nodesIdListToRemove.length === 1 ? [selectedNode] : [])
            break
          case 'linknode':
            lsatConnections = getLsatConnections(selectedNode)
            if(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2) {
              lsatConnections.forEach(lsatConnection => nodesListToBeChecked.push(lsatConnection.id))
              getConnectedHubNode = getNodeById(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0])
              satConnections = getSatConnections(getConnectedHubNode)
              satConnections.forEach(satConnection => nodesListToBeChecked.push(satConnection.id))
              nodesIdListToRemove = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES], ...nodesListToBeChecked ]
              linkXlinkConnections = getLinkXlinkConnections(getConnectedHubNode)
              linkXlinkConnections.forEach(linkXlinkNode => {
                if(linkXlinkNode.type === 'linknode') {
                  if(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].length > 2)  nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][3])
                  else nodesListToBeChecked.push(linkXlinkNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
                }
                else nodesListToBeChecked.push(linkXlinkNode.id)
              })
            } else {
              if(lsatConnections.length > 1) {
                lsatConnections.forEach(lsatConnection => nodesListToBeChecked.push(lsatConnection.id))
                nodesIdListToRemove = [  ...nodesListToBeChecked ]
                nodesIdListToRemove.push(selectedNode.id)
              } else {
                nodesListToBeChecked.push(selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1])
                nodesIdListToRemove = [ ...selectedNode[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES] ]
              }
            }
            nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser(nodesListToBeChecked)
            if(nodeSettingsOpenedBy === '') {
              linkXlinkConnections.forEach(linkXlinkNode => {
                handleLinkStatus(linkXlinkNode.id, getConnectedHubNode.id, 'remove')
              })
            } else {
              nodesIdListToRemove = []
              GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
            }
            updatedElementsListToRemove = getNodesByNodeIdList(nodesIdListToRemove)
            break
          default:
            break
        }
      }
    } else {
      const { source, target } = selectedNode
      const nodeTypesPattern = /^HUB|^LINK|^XLINK_DELTA|^XLINK_FULL|^SAT|^MSAT|^LSAT|^REF/
      const sourceNodeType = source.match(nodeTypesPattern)[0]
      const targetNodeType = target.match(nodeTypesPattern)[0]
      switch(true) {
        case (['LINK', 'XLINK_DELTA', 'XLINK_FULL'].includes(sourceNodeType) && targetNodeType === 'HUB'):
          // Check if that belongs to HUB||SAT||LINK||LSAT pair
          if(sourceNodeType === 'LINK') {
            sourceNode = getNodeById(source)
            targetNode = getNodeById(target)
            if(sourceNode[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === 'HUB||SAT||LINK||LSAT' && targetNode[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === 'HUB||SAT||LINK||LSAT') {
              GlobalNotificationHandle({ key: "CANNOT_DELETE_EDGE" })
            } else {
              if(window.confirm('Selected edge will be deleted.')) {
                // nodeSettingsOpenedBy = await checkLinkLsatNodeSettingsOpen(sourceNode)
                nodeSettingsOpenedBy = await checkLinkLsatNodeSettingsOpen(sourceNode)
                if(nodeSettingsOpenedBy === '') {
                  updatedElementsListToRemove.push(selectedNode)
                  handleLinkStatus(source, target, 'remove')
                } else {
                  GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
                }
              }
            }
          } else {
            if(window.confirm('Selected edge will be deleted.')) {
              nodeSettingsOpenedBy = await checkNodeSettingsOpenByOtherUser([sourceNode])
              if(nodeSettingsOpenedBy === '') {
                updatedElementsListToRemove.push(selectedNode)
                handleLinkStatus(source, target, 'remove')
              } else {
                GlobalNotificationHandle({ key: 'CANNOT_DELETE', messageFiller: {nodeSettingsOpenedBy} })
              }
            }
          }
          break
        default:
          GlobalNotificationHandle({ key: 'CANNOT_DELETE_EDGE_OF_NODE' })
      }
    }
    if(updatedElementsListToRemove.length > 0) {
      setElements((els) => removeElements(updatedElementsListToRemove, els));
      setTimeout(() => {
        updateDesign()
      }, updateDelay)
    }
  }

  const checkNodeSettingsOpenByOtherUser = async (nodeIdList) => {
    let nodeSettingsOpenedByOtherUser = ''
    if(socket !== null && socket !== undefined) {
      const promise = await new Promise(resolve => {
        socket.emit('checkNodeSettings', {
        tableId: currentDesign.designId, 
        nodeIdList
        },
        user => {
        if(user !== '') {
          nodeSettingsOpenedByOtherUser = user
        }
        resolve(user)
        })
      })
      await promise
    }
    return nodeSettingsOpenedByOtherUser
  }

  const checkLinkLsatNodeSettingsOpen = async (linkNode) => {
    let lsatellites = getLsatConnections(linkNode)
    const nodeIdList = lsatellites.map(lsatellite => lsatellite.id)
    return await checkNodeSettingsOpenByOtherUser(nodeIdList)
  }

  const handleLinkStatus = async (linkId, hubId, action) => {
    let lsatellites = [], enableSettings, businessKeyId
    setElements((prevElements) => {
      const elementsList = prevElements
      elementsList.forEach(nodeItem => {
        if (nodeItem.id === linkId) {
          if (!(hubId in nodeItem[DATA][HUB_CONNECTIONS]) && action === 'add') {

            // get hub name by Id
            let hubName;
            try {
              elementsList.forEach(item => {
                if (item.id === hubId) {
                  hubName = item[DATA][LABEL].replace(/^HUB_/, '')
                  // break on success
                  throw 'success'
                }
              })
            } catch (e) {
            }

            // hub connections is a list of hub objects and names
            nodeItem[DATA][HUB_CONNECTIONS][hubId] = hubName
            nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].push(hubId)

            nodeItem[DATA][LABEL] = `${nodeItem[DATA][LABEL]}_${hubName}`
            if (nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].length > 1) {
              nodeItem[DATA][LINK_STATUS] = false
              enableSettings = true
            }
          } 
          // Delete the Hub node from the link node hub_connections as the Hub node was deleted
          if ((hubId in nodeItem[DATA][HUB_CONNECTIONS]) && action === 'remove') {

            // get hub name by Id
            let hubName;
            try {
              elementsList.forEach(item => {
                if (item.id === hubId) {
                  hubName = item[DATA][LABEL].replace(/^HUB/, '')
                  // break on success
                  throw 'success'
                }
              })
            } catch (e) {
            }
            businessKeyId = nodeItem[DATA][HUB_CONNECTIONS][hubId]
            delete nodeItem[DATA][HUB_CONNECTIONS][hubId]
            const indexOfHubNode = nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].indexOf(hubId)
            if(indexOfHubNode !== -1) nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].splice(indexOfHubNode, 1)
            if (nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].length < 2) {
              nodeItem[DATA][LINK_STATUS] = true
              enableSettings = false
            }
            let updateNodeLabel = nodeItem[DATA][LABEL].split('_')[0]
            nodeItem[DATA][HUB_CONNECTIONS]["hubOrder"].forEach(connectedHubId => {
              updateNodeLabel += `_${nodeItem[DATA][HUB_CONNECTIONS][connectedHubId]}`
            })
            nodeItem[DATA][LABEL] = updateNodeLabel
              
            if(nodeItem[DATA][USER_SETTINGS] !== undefined && nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY] !== undefined && nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY][businessKeyId] !== undefined)  delete nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY][businessKeyId]
          }
          lsatellites = getLsatConnections(nodeItem)
        }
      })
      return [...elementsList]
    })
    if (lsatellites.length > 0 && enableSettings !== undefined) {
      setElements((prevElements) => {
        const elementsList = prevElements
        elementsList.forEach(nodeItem => {
          for (let satellite of lsatellites) {
            if (satellite.id === nodeItem.id) {
              nodeItem[DATA][ENABLE_NODE_SETTINGS] = enableSettings
              if(action === 'remove') {
                if(nodeItem[DATA][USER_SETTINGS] !== undefined && nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY] !== undefined && nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY][businessKeyId] !== undefined)  delete nodeItem[DATA][USER_SETTINGS][BUSINESS_KEY][businessKeyId]
              }
              break
            }
          }
        })
        return [...elementsList]
      })
    }
  }

  const updateHubLinkConnectedEntities = (nodeId, satNode) => {
    setElements(prevElements => {
      const elementsList = [ ...prevElements ]
      elementsList.forEach(element => {
        if(element.id === nodeId) {
          element[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] = satNode.id
          element[DATA][SOURCE_TABLE] = satNode[DATA][SOURCE_TABLE]
        }
      })
      return [ ...elementsList ]
    })
  }

  return (
    <PlaygroundContext.Provider value={{ updateDesign, elements, setElements, onElementsRemove, handleLinkStatus, checkLinkLsatNodeSettingsOpen, checkNodeSettingsOpenByOtherUser, updateHubLinkConnectedEntities, selectedElements, setSelectedElements }}>
      {children}
    </PlaygroundContext.Provider>
  )
}

export const usePlaygroundContext = () => {
  return useContext(PlaygroundContext)
}