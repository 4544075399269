import { createContext, useState, useContext, useEffect } from "react";
import io from 'socket.io-client'
import { GlobalNotificationHandle } from "../Logic/NotificationHandler";
import { useAuth } from "./AuthContext";
import { useLoaderContext } from "./LoadingContext";

export const SocketioContext = createContext(null)

export const SocketioProvider = ({ children }) => {
  const { setIsLoading } = useLoaderContext();
  const { user, jwtToken, logout } = useAuth()
  const [ socket, setSocket ] = useState(null)

  useEffect(async () => {
    if(user !== null) {
      const token = await jwtToken()
      const s = io({
        auth: {
          token: `${token}`
        },
        query: {
          "username": `${user?.attributes?.given_name} ${user?.attributes?.family_name}`
        },
        transports: ['websocket']
      })
      setSocket(s)
    }

  }, [user])

  useEffect(() => {
    if(socket !== null & socket !== undefined) {
      //Socket event 'connect_error' received from server in case of authentication failure
      socket.on('connect_error', (error) => {
        setIsLoading(false);
        if(error.message === 'Token expired' || error.message === 'xhr poll error') {
          logout()
        }
        else {
        GlobalNotificationHandle({ key: 'GENERAL_ERROR_MESSAGE', messageFiller: {error: error.message} })
        }
      })

      //Socket event 'error' received from server with errors encountered while processing at server
      socket.on('error', (error) => {
        GlobalNotificationHandle({ message: `${error.message}`, isIncident: error.isIncident || false, isTicketIssue: error.isTicketIssue || false })
        setIsLoading(false);
      })
    }
  }, [socket])

  return (
    <SocketioContext.Provider
      value={{socket}}
    >
      {children}
    </SocketioContext.Provider>
  )
}

export const useSocketioContext = () => {
  return useContext(SocketioContext)
}