import React from "react";
import { toast, Bounce } from "react-toastify";
import {CLIENT_ERROR, CLIENT_SUCCESS, CLIENT_WARNING, CLIENT_INFO, ALERT_DETAILS } from "../Types/AlertTypes";
import { formatMessage } from "../helpers/formatErrorText";

/*-------function that handle all type of error and success notifications----*/
export function GlobalNotificationHandle({
  key,
  message, // Use message property only for backend errors, for front end errors use key and mention message in AlertTypes
  messageFiller,
  isIncident,
  isTicketIssue,
}) {
  let alertMessage = "", variant = CLIENT_ERROR, isIncidentAlert = false, isTicketIssueAlert = false
  if(key) {
    const alertDetails = ALERT_DETAILS[key]
    variant = alertDetails["variant"]
    isIncidentAlert = isIncident || alertDetails["isIncident"]
    isTicketIssueAlert = isTicketIssue || alertDetails["isTicketIssue"]
    alertMessage = messageFiller ? formatMessage(alertDetails["message"], messageFiller) : alertDetails["message"]
  } else {
    if(message) {
      alertMessage = message
      isIncidentAlert = isIncident
      isTicketIssueAlert = isTicketIssue 
    }
  }
  const finalMessage = () => {
    if(isIncidentAlert) {
      return (
        <span>
          {alertMessage}
          Please raise an incident here: <a href="https://roche.service-now.com/icare?id=sc_cat_item&sys_id=2958cf76871dd6901f3c63560cbb3513" alt="incident link">incident request</a>
        </span>
      )
    } else if(isTicketIssueAlert) {
      return (
        <span>
          {alertMessage}
          If this should not be the case please raise a ticket here: <a href="https://roche.service-now.com/icare?id=sc_cat_item&sys_id=a5c462af875952149508c8480cbb3524" alt="incident link">ticket request</a>
        </span>
      )
    } else {
      return (
        <span>
          {alertMessage}
        </span>
      )
    }
  }

  switch(variant) {
    case CLIENT_ERROR:
      toast.error(
        <>
          {finalMessage()}
        </>,
        {
          toastId: key ? key : message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      )
      break;
    case CLIENT_SUCCESS:
      toast.success(
        <>
          {finalMessage()}
        </>,
        {
          toastId: key ? key : message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      )
      break;
    case CLIENT_WARNING:
      toast.warn(
        <>
          {finalMessage()}
        </>,
        {
          toastId: key ? key : message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      )
      break;
    case CLIENT_INFO:
      toast.info(
        <>
          {finalMessage()}
        </>,
        {
          toastId: key ? key : message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      )
      break;
    default:
      toast.error(
        <>
          {finalMessage()}
        </>,
        {
          toastId: key ? key : message,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      )
  }
}
