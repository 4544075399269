import { useState, useContext, createContext, useEffect } from "react";
import {
  HUB_SATELLITE,
  LINK_SATELLITE,
  REF_SATELLITE,
  XREF,
  XLINK_DELTA,
  XLINK_FULL,
  HUB_SAT_LINK_LSAT,
} from "../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes";
import { useSocketioContext } from "./SocketioContext";
import { useAuth } from "../Context/AuthContext";

const nodeLinkListDefault = [
  HUB_SATELLITE,
  LINK_SATELLITE,
  REF_SATELLITE,
  XREF,
  XLINK_DELTA,
  XLINK_FULL,
  HUB_SAT_LINK_LSAT,
];
export const SnowFlakeContext = createContext(null);

export const SnowFlakeProvider = ({ children }) => {
  const { socket } = useSocketioContext();
  const [dvaVersion, setDvaVersion] = useState('')
  const [databaseState, setDatabaseState] = useState({
    database: "",
    schema: "",
    table: "",
    nodeLink: "",
    destinationSchema: "",
    destinationDataBase: "",
    databaseList: [],
    schemaList: [],
    tableList: [],
    nodeLinkList: [
      HUB_SATELLITE,
      LINK_SATELLITE,
      REF_SATELLITE,
      XREF,
      XLINK_DELTA,
      XLINK_FULL,
      HUB_SAT_LINK_LSAT,
    ],
  });
  const { snowflakeAccount, accountsList } = useAuth();

  useEffect(() => {
    if (socket !== null) {
      //Socket event 'receiveDatabases' sent from server with databases list as response
      socket.on("receiveDatabases", async (responsePayload) => {
        setDatabaseState((prevState) => {
          return { ...prevState, databaseList: responsePayload.databases };
        });
      });

      //Socket event 'receiveSchemas' sent from server with schemas list as response
      socket.on("receiveSchemas", async (responsePayload) => {
        setDatabaseState((prevState) => {
          return { ...prevState, schemaList: responsePayload.schemas };
        });
      });

      //Socket event 'receiveTables' sent from server with tables list as response
      socket.on("receiveTables", async (responsePayload) => {
        setDatabaseState((prevState) => {
          return { ...prevState, tableList: responsePayload.tables };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    fetchSchemas(
      databaseState.database,
      accountsList[snowflakeAccount]?.accountURL
    );
  }, [databaseState.database]);

  useEffect(() => {
    if (databaseState.schema !== "" && socket !== null) {
      //Socket event 'getTables' requested from client to get the tables list from server
      socket.emit("getTables", {
        database: databaseState.database,
        schema: databaseState.schema,
        account: accountsList[snowflakeAccount]?.accountURL,
      });
    } else {
      setDatabaseState((prevState) => {
        return { ...prevState, tableList: [] };
      });
    }
  }, [databaseState.database, databaseState.schema]);

  useEffect(() => {
    if (databaseState.table !== "") {
      setDatabaseState((prevState) => {
        return {
          ...prevState,
          nodeLinkList: [...nodeLinkListDefault],
        };
      });
    } else {
      setDatabaseState((prevState) => {
        return { ...prevState, nodeLinkList: [] };
      });
    }
  }, [databaseState.table]);

  const initializeDatabaseState = () => {
    setDatabaseState({
      database: "",
      schema: "",
      table: "",
      nodeLink: "",
      destinationSchema: "",
      destinationDataBase: "",
      databaseList: [],
      schemaList: [],
      tableList: [],
      nodeLinkList: [
        HUB_SATELLITE,
        LINK_SATELLITE,
        REF_SATELLITE,
        XREF,
        XLINK_DELTA,
        XLINK_FULL,
        HUB_SAT_LINK_LSAT,
      ],
    });
  };

  const retainDatabaseValue = (retainedDatabase, retainedSchema) => {
    setDatabaseState((prevState) => {
      return {
        ...prevState,
        database: retainedDatabase,
        schema: retainedSchema,
      };
    });
  };

  const fetchSchemas = async (selectedDatabase, account) => {
    if (selectedDatabase !== "") {
      //Socket event 'getSchemas' requested from client to get the schemas list from server
      socket.emit("getSchemas", {
        database: selectedDatabase,
        account: account,
      });
    }
  };

  return (
    <SnowFlakeContext.Provider
      value={{
        ...databaseState,
        setDatabaseState,
        initializeDatabaseState,
        fetchSchemas,
        retainDatabaseValue,
        dvaVersion, 
        setDvaVersion
      }}
    >
      {children}
    </SnowFlakeContext.Provider>
  );
};

export const useSnowFlakeContext = () => {
  return useContext(SnowFlakeContext);
};
