import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { useDesignsContext } from "../Context/DesignsContext"
import { USER_TUTORIAL } from '../Types/MenuTypes'
import { useSocketioContext } from '../Context/SocketioContext'
import PaginationComponent from './PaginationComponent';
import { useAuth } from "../Context/AuthContext";
import SimmerUI from './SimmerUI';
import Link from '@mui/material/Link';
import AccountSelectDropdown from './AccountSelectDropdown';
import RecentDesignContent from './RecentDesignContent';

const MainContentTop = ({ account, setAccount, getRecentData, recentDesign, toggleData }) => {

    const { openDesign } = useDesignsContext()
    const { socket } = useSocketioContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [topLimit, setTopLimit] = useState(3);
    const [authDesign, setAuthDesign] = useState();
    const [totalPages, setTotalPages] = useState();
    const [haveDesign, setHaveDesign] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, setSnowflakeAccount, getAccountNameByURL, accountsList } = useAuth();
    const usernameValue = user?.attributes?.name;
    useEffect(() => {
        setLoading(true);
        if (account !== "") {
            getAuthorizedData(account, currentPage, topLimit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, currentPage])
    
    useEffect(() => {
        if (account !== "") {
            socket?.on("receiveAuthorizedDesigns", (data) => {
                setAuthDesign(data.data.authorizedDesigns)
                setTotalPages(data.data.pageCount);
                setLoading(false);
                if (data.data.authorizedDesigns.length) {
                    setHaveDesign(true);
                } else {
                    setHaveDesign(false);
                }

            });
        }
    }, [account]);

    const getAuthorizedData = (account, currentPage, topLimit) => {
        const requestPayload = {
            account: account,
            limit: topLimit,  // number to be kept so design doesn't overflow
            currentPage: currentPage,
        }
        socket?.emit("getAuthorizedDesigns", requestPayload);
    }
    const handleClick = async () => {
        openDesign(USER_TUTORIAL)
    }
    const dateTimeFormatter = (dateTime) => {
        const date = new Date(dateTime)
        const formattedDate = date.toLocaleDateString()
        const formattedTime = date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
        return `${formattedDate} ${formattedTime}`
    }

    return (
        <>
        <div className="fs-4 fw-bold main-content-top-bar">
          <div className='main-content-top-bar-left'>{`Welcome ${user?.attributes?.given_name} ${user?.attributes?.family_name}`}</div>
          <div className='main-content-top-bar-right'>
            <span className='account-select-label' >Account: </span>
            <AccountSelectDropdown account={account} setAccount={setAccount} setLoader={setLoading} getRecentData={getRecentData} toggleData={toggleData}/>
            {/* <MainContentTopSearch authDesign={authDesign} searchResult ={searchResult} setSearchResult={setSearchResult}/> */}
          </div>
        </div>
        <div className="main_page_top">
            <div className="main-content-top-heading">Designs assigned to you:</div>            
                <>{haveDesign ? 
                    (<div>
                        {loading ? <SimmerUI /> :<><div className='card_wrapper mt-2'>
                        {authDesign?.map(design => {
                            return (
                                <Card variant="outlined" className="card_display"
                                    key={design?.CHART_ID}
                                    onClick={() => openDesign(design?.CHART_ID, design)}
                                    role='button'>
                                    <div className="card_display_table_name">
                                        {design?.TABLE_NAME}
                                    </div>
                                    <div className="card_display_business_name">
                                        {design?.BUSINESS_NAME}
                                    </div>
                                    <div className="card_display_updatedby">
                                        <span className="card_label">Last updated by: </span>
                                        <span className="card_text">{design?.UPDATED_BY}</span>
                                    </div>
                                    <div className='card_display_updated_timestamp'>
                                        <span className="card_label">Last update timestamp: </span>
                                        <span className="card_text">{dateTimeFormatter(design?.LAST_UPDATED)}</span>

                                    </div>

                                </Card>
                            )
                        })}
                    </div>

                        {totalPages > 1 ? <PaginationComponent account={account} totalPages={totalPages} getData={getAuthorizedData} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={3} /> : ""}
                        <div className="main-content-top-nocard my-2">
                            <div className="main-content-top-new-to-dva-with-design">New to DVA? </div>
                            <div className="main-content-top-request-sandbox"
                                onClick={handleClick}>
                                Try out Sandbox
                            </div>
                        </div></>
                    }</div>):
                    (<div>
                        <div className="main-content-top-nocard main-content-top-no-design-text">
                            You are not yet assigned to a design. Please request access, or explore our Sandbox environment
                        </div>
                        <div className="main-content-top-nocard">
                            <div className="main-content-top-request-design-button"><Link target="_blank" href="https://roche.service-now.com/icare?id=sc_cat_item&sys_id=a5c462af875952149508c8480cbb3524">Request design access</Link></div>
                            <div className="main-content-top-new-to-dva-without-design">New to DVA? </div>
                            <div className="main-content-top-request-sandbox"
                                onClick={handleClick}>
                                Try out Sandbox
                            </div>
                        </div>
                    </div>)
                }</>
            {toggleData === "Database" ? "" : recentDesign?.length ? <RecentDesignContent recentDesign={recentDesign}/> : ""}
        </div>
        </>
    )
}

export default MainContentTop
