import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext'
import Button from 'react-bootstrap/Button'
import { useEffect } from 'react';
import { ReactComponent as RocheLogoBlue } from '../Assets/svg/RocheLogoBlue.svg';

const LoginPage = () => {
  const { user, login } = useAuth()
  
  const navigate = useNavigate()

  const handleLogin = () => {
    login()
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  return (
    <div className="App container-fluid">
      <div className="position-relative vh-100">
        <div className="login-page-logo">
        <RocheLogoBlue /> 
        </div>
        <div className="position-absolute top-50 translate-middle-y w-100">
          <div className="text-center">
            <h1>Welcome to Data Vault Automation Application</h1>
            <br/>
            <div className="w-100 text-center">
              <Button className = "custom-button" size="lg" onClick={() => handleLogin()}>Sign In</Button>
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-0 mx-5">
          <div>
            <strong>Confidentiality Disclaimer</strong>
          </div>
          <div>
            By logging in to Data Vault Automation application, I understand that the use of this system is governed by the Roche Code of Conduct and associated policies and directives. By accessing this system, I agree to comply with the provisions of these policies and directives and understand that non-compliance may have severe consequences. In addition, I understand that my use of this system may be monitored in accordance with local laws.
          </div>
          <br/>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;