export const formatMessage = (errorMessage, parameterObj) => {

  const formattedErrorMessage = Object.keys(parameterObj).reduce((formattedText, item) => {
    let regex1 = new RegExp(`{{${item}}}`, "g");
    console.log(regex1)
    console.log(parameterObj[item])
    formattedText = formattedText.replace(regex1, parameterObj[item])
    return formattedText
  }, errorMessage)

  return formattedErrorMessage
}