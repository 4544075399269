import Dropdown from "react-bootstrap/Dropdown";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect, memo } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useDesignsContext } from "../Context/DesignsContext";

const CustomDropdown = ({
  needToBeAuthorized = true,
  dropdownList,
  dropdownName,
  handleSelection,
  selectedValue,
  filterable,
  clipLength,
  freeze,
  customClassname = "",
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([...dropdownList]);
  const [selectedOption, setSelectedOption] = useState(dropdownName);
  const { currentDesign } = useDesignsContext();

  const filterList = (event) => {
    setFilterInput(event.target.value);
  };

  useEffect(() => {
    if (filterInput !== "") {
      const newDropdownOptions = dropdownList.filter((listItem) => {
        if (listItem === null || listItem === undefined) return false;
        return listItem.toUpperCase().includes(filterInput.toUpperCase());
      });
      setFilteredOptions([...newDropdownOptions]);
    } else {
      setFilteredOptions([...dropdownList]);
    }
  }, [filterInput, dropdownList]);

  useEffect(() => {
    setFilterInput("");
    setSelectedOption(dropdownName);
  }, [dropdownList]);

  const onClick = (event) => {
    event.preventDefault();
    const optionValue = event.target.textContent;
    setSelectedOption(optionValue);
    handleSelection(optionValue);
  };

  const isDisabled = freeze === true || (needToBeAuthorized && currentDesign.authorized === false);
  
  const tooltipTitle = isDisabled
  ? " You do not have permission to select this option"
  : selectedValue.length > clipLength
  ? selectedValue 
  : "";

return (
  <Dropdown
    style={{
      cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor to not-allowed if disabled
    }}
  >
    {/* Only display the Tooltip on hover */}
    <Tooltip title={tooltipTitle} placement="bottom" arrow>
      <span>
        <Dropdown.Toggle
          id={dropdownName}
          data-testid={`${dropdownName}-input`}
          variant="primary"
          className={classNames("w-100", customClassname)}
          disabled={isDisabled}
        >
          {selectedValue !== undefined && selectedValue !== "" ? (
            selectedValue.length > clipLength ? (
              // Truncate the selected value if it exceeds clipLength
              <span>{`${selectedValue.substring(0, clipLength)}...`}</span>
            ) : (
              selectedValue // Display the full selected value if it doesn't exceed clipLength
            )
          ) : selectedOption.length > clipLength ? (
            // Handle when no selected value is provided and dropdownName is used
            <span>{`${selectedOption.substring(0, clipLength)}...`}</span>
          ) : (
            selectedOption // Otherwise, show the selected option (dropdownName)
          )}
        </Dropdown.Toggle>
      </span>
    </Tooltip>

    <Dropdown.Menu variant="light">
      {filterable && (
        <Form
          className="d-flex dropdown-item p-2"
          onSubmit={(event) => event.preventDefault()}
        >
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={filterList}
            autoFocus
          />
        </Form>
      )}
      <div
        style={{
          height: filteredOptions.length > 6 ? "200px" : "auto",
          overflowY: "auto",
        }}
      >
        {filteredOptions.map((item, index) => {
          return (
            <Dropdown.Item onClick={onClick} key={`drpdown-${index}`} data-testid={`${dropdownName}-value-${item}`}>
              {item}
            </Dropdown.Item>
          );
        })}
      </div>
    </Dropdown.Menu>
  </Dropdown>
);
};

CustomDropdown.defaultProps = {
  clipLength: 12,
  freeze: false,
  selectedValue: "",
};




export default memo(CustomDropdown);
